// Responsive
$mobile: 767px;
$tablet: 1024px;
$gutter-mobile: 20px;
$gutter-tablet: 30px;

// Colors
$text-primary: #ffffff;
$text-secondary: #eeeeee;

$primary-main: #f75425;
$primary-light: #fb825f;

$secondary-main: #ffe1d6;
$secondary-light: #fff3ef;
