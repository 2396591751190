@import "styles/variables.scss";

.button:global(.ant-btn.ant-dropdown-trigger) {
  display: flex;
  align-items: center;

  background-color: #4f4f4f;
  border-radius: 40px;

  @media (max-width: $mobile) {
    width: 100%;
    margin-bottom: 16px;
  }

  &:hover {
    background-color: #333333;
  }
}

.button :global(.anticon-down) {
  margin-top: 4px;
}

.dropdown:global(.ant-dropdown) {
  padding: 16px 8px;
  border-radius: 16px;
  background-color: #333333;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 4px 0, rgba(0, 0, 0, 0.25) 0 1px 2px 0;
}

.dropdown :global(.ant-dropdown-menu) {
  background-color: #333333;
  box-shadow: none;
}

.menuItem {
  color: $text-primary;
  font-size: 16px;
  font-weight: 600;
}

.menuItem:hover {
  color: $text-primary;
  background: none;
}

.activeMenu {
  color: #fff;
}
