@import "styles/variables.scss";

.header:global(.ant-layout-header) {
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 16px;
  padding: 0 40px;

  @media (max-width: $tablet) {
    padding-right: $gutter-tablet;
    padding-left: $gutter-tablet;
  }

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;

    height: auto;
    margin-bottom: 10px;

    padding-right: $gutter-mobile;
    padding-left: $gutter-mobile;

    & > svg {
      margin-bottom: 20px;
    }
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;

  @media (max-width: $mobile) {
    flex-direction: column;
    width: 100%;
  }
}

.accountWrapper {
  margin-left: 24px;

  @media (max-width: $mobile) {
    width: 100%;
    margin-left: 0;
  }
}

.account {
  display: flex;
  align-items: center;
  color: $text-primary;
  background-color: $primary-main;
  border-radius: 40px;
  padding: 0;
  border: none;
  cursor: pointer;

  @media (max-width: $mobile) {
    display: flex;
    align-items: center;

    width: 100%;
  }
}

.balance,
.walletAddress {
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  display: inline-block;
}

.balance {
  padding: 8px;
}

.walletAddress {
  background-color: $primary-light;
  border-radius: 40px;
  padding: 8px 34px;

  @media (max-width: $mobile) {
    margin-left: auto;
    padding: 8px 40px;
  }
}

.accountLogo {
  margin: 0 4px;
}

.connectButton {
  @media (max-width: $mobile) {
    width: 100%;
  }
}
