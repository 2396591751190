@import "styles/variables";

.alert {
  border: none;
  padding: 12px;
  text-align: center;

  @media (max-width: $mobile) {
    text-align: left;
    padding: 24px;
  }
}

.alertText {
  color: $text-primary;
  font-size: 16px;
}

.error {
  background-color: #c13d54;

  @media (max-width: $mobile) {
    border-radius: 16px;
  }
}
