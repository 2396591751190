@import "styles/variables";
@import "~antd/dist/antd.css";

html,
body {
  @media (max-width: $mobile) {
    height: auto;
    min-height: 100vh;
  }
}

body {
  font-family: "Source Sans Pro", sans-serif;
  background: linear-gradient(
    120.21deg,
    #ffffff -5.83%,
    #f7f4f1 75.51%,
    #f7f4f1 100%
  );
}
