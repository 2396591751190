@import "styles/variables.scss";

.modal {
  @media (max-width: $mobile) {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
}

:global(.ant-modal-wrap) {
  background: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(10px);

  @media (max-width: $mobile) {
    background-color: #fff;
  }
}

.modal :global(.ant-modal-content) {
  background-color: #fff;
  color: #333;
  border-radius: 16px;

  position: relative;
  background-clip: padding-box;
  border: 1px solid #afafaf;

  @media (max-width: $mobile) {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
    border-radius: 0;
  }
}

.modal :global(.ant-modal-close) {
  text-indent: -9999px;
  top: 28px;
  right: 20px;

  &::before,
  &:after {
    content: " ";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    width: 17px;
    height: 3px;
    margin: auto;

    background-color: #333;
    border-radius: 3px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: $mobile) {
    top: 15px;
    right: 0;
  }
}

.modal :global(.ant-modal-close-x) {
  color: #333;
}

.modal :global(.ant-modal-body) {
  padding: 40px;

  @media (max-width: $mobile) {
    padding: 25px $gutter-mobile;
  }
}

.modal :global(.ant-modal-body .ant-col > .ant-btn) {
  width: 100%;
  height: 52px;
}
