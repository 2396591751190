@import "styles/variables.scss";

.spin {
  display: block;
  color: $secondary-light;
  margin: 40px auto;
}

.spin :global(.ant-spin-dot) {
  font-size: 100px;
}
