.notification {
  background: #1f1f1f;
  border-radius: 16px;
}

.notification :global(.ant-notification-notice-message) {
  color: #fabda5;
  font-weight: 600;
  font-size: 18px;
}

.notification :global(.ant-notification-notice-description) {
  font-size: 18px;
  color: #fff;
  padding-right: 24px;
}

.notification :global(.ant-notification-notice-close) {
  color: #fff;
  transform: translate(0, 50%);
}

.notification :global(.ant-notification-close-icon) {
  font-size: 24px;
}

.notification :global(.ant-notification-notice-icon) {
  top: 10px;
  transform: translate(0, 50%);
}
