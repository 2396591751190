.detailsText {
  display: flex;
  flex-direction: column;
  min-width: 110px;
  color: #333;
}

.label {
  font-size: 16px;
  margin-bottom: 3px;
}

.text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
