@import "styles/variables.scss";

.title {
  font-weight: 600;
  font-size: 24px;
  color: #333;
  line-height: 36px;
  margin-bottom: 20px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #333;
  min-height: 100px;
  border-radius: 16px;

  &:hover,
  &:focus {
    background-color: $secondary-light;
  }
}

.buttonText {
  font-size: 12px;
  margin-top: 8px;
}
