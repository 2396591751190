@import "styles/variables.scss";

.card {
  background-color: #fff;
  position: relative;
  background-clip: padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 16px;
}

.card :global(.ant-card-body) {
  padding: 0;
}

.cardHeader {
  padding: 20px 75px 20px 25px;
  display: flex;

  @media (max-width: $tablet) {
    position: relative;

    flex-wrap: wrap;
  }
}

.titleWrapper {
  @media (max-width: $tablet) {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.title {
  color: #333;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}

.status {
  font-size: 13px;
  line-height: 19px;
  color: #fff;
  background-color: #27ae60;
  border-radius: 40px;
  padding: 2px 20px;
  text-transform: capitalize;
}

.line {
  height: 1px;
  background: #e2e2e2;
}

.totalInfo {
  margin-left: auto;
  display: flex;

  @media (max-width: $tablet) {
    width: 100%;
    margin-left: 0;

    justify-content: space-between;
  }
}

.totalInfo > div:last-child {
  margin-left: 60px;

  @media (max-width: $tablet) {
    margin-left: 0;
  }
}

.totalStakeInfo {
  display: flex;
  margin-left: auto;

  @media (max-width: $tablet) {
    flex-direction: column;
    margin-left: 0;
  }

  & > div:last-child {
    margin-left: 60px;

    @media (max-width: $tablet) {
      margin-left: 0;
    }
  }
}

.cardBody {
  padding: 20px 75px 20px 25px;
  display: flex;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
  }
}

.dates {
  display: flex;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.dates > div:not(:first-child) {
  margin-left: 35px;

  @media (max-width: $tablet) {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  padding: 22px 26px 28px;
  border-top: 1px solid #e2e2e2;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
  }
}

.cardFooter > button {
  width: 100%;
  margin: 0 10px;

  @media (max-width: $tablet) {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.cardFooter > button:first-child {
  margin-left: 0;
}

.cardFooter > button:last-child {
  margin-right: 0;
}
