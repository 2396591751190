@import "styles/variables.scss";

.title {
  font-size: 24px;
  line-height: 36px;
  color: #333333;
  font-weight: 600;

  margin-bottom: 34px;

  @media (max-width: $mobile) {
    margin-bottom: 10px;
  }
}

.tabs:global(.ant-tabs) {
  color: #828282;
}

.tabs:global(.ant-tabs) > :global(.ant-tabs-nav):before {
  border: none;
}

.tabs :global(.ant-tabs-tab) {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  padding-bottom: 6px;
}

.tabs :global(.ant-tabs-tab-active .ant-tabs-tab-btn),
.tabs :global(.ant-tabs-tab):hover {
  color: $primary-main;
  text-shadow: none;
}

.tabs :global(.ant-tabs-ink-bar) {
  background-color: $primary-main;
}
