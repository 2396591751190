@import "styles/variables.scss";

.grid {
  margin: 20px 0 30px;
}

.grid :global(.ant-col) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #39425e;

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: self-end;
  }
}

.gridTitle {
  width: 27%;
  padding: 8px 0;
  font-size: 13px;

  @media (max-width: $mobile) {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0;
  }
}

.earningMainCurrency,
.earningConverted {
  width: 20%;
  padding: 8px 2px;
  font-size: 13px;

  @media (max-width: $mobile) {
    width: auto;
    padding: 0;
  }
}

.earningMainCurrency {
  width: 33%;

  @media (max-width: $mobile) {
    width: auto;
    padding: 0;
  }
}

.earningConverted {
  text-align: right;
}

.title {
  font-size: 18px;

  @media (max-width: $mobile) {
    height: 40px;
    flex-grow: 1;
  }
}

.value {
  font-size: 18px;
  color: #333;
}
