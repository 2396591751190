@import "styles/variables";

.title {
  font-weight: 600;
  font-size: 24px;
  color: #333;
  line-height: 36px;
  margin-bottom: 20px;
}

.row {
  margin-bottom: 20px;
}

.rowTitle {
  font-size: 12px;
  margin-bottom: 4px;
}

.rowText {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
}

.account {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 600;
  background-color: $primary-light;
  border-radius: 16px;
  padding: 6px;
}

.walletAddress {
  padding-left: 8px;
  font-size: 16px;
}
